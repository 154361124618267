<template>
  <div>
    <b-row style="margin-bottom: -10px;;">
        <b-col> <h6 ><feather-icon size="15" icon="LayersIcon"  /> Daftar Transaski {{ st }}</h6></b-col>
    </b-row><hr>
    <b-row style="margin-top: -10px;">
        <b-col md="4" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="2" sm="8" class="my-1">
             
        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group  label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" /> 
                </b-input-group>
            </b-form-group>
        </b-col> 
        <b-col cols="12">
           
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(tagihan)="data">
                    <b-row>
                        <b-col md="6">
                            <small>NIM - Nama  </small>
                            <h5>{{ data.item.nim }} - <span>{{ data.item.nm_lengkap }}</span> <br> <small>Kelas {{ data.item.Pagi }}, Tahun Angkatan {{ data.item.th_angkatan }}</small></h5>
                            
                            <h5>{{ data.item.nm_jenis_pembayaran }} Kelas {{ data.item.kelas_pembayaran }} <br> <small>Periode {{ data.item.nm_semester }} </small></h5>
                            <b-button block variant="primary" size="sm"  v-b-modal.modal-1 @click="getHistori(data.item)">
                                <feather-icon size="15" icon="LayersIcon" class="text-white" /> Detail Tagiha 
                            </b-button>
                        </b-col>
                        
                        <b-col md="6">
                            <small>No Invoice</small>
                            <h6 class="text-primary">{{ data.item.no_inv }} </h6>
                            <small>Tgl Create</small>
                            <h6>{{ data.item.date_c }} </h6>
                            <small class="text-warning">Nominal Tertagih</small>
                            <h2 class="text-warning" ><strong><sup><small>Rp. </small></sup>{{ Rp(data.item.terbayar) }} </strong></h2>
                        </b-col>  
                    </b-row>
                </template>
            </b-table>
            <div v-if="items.length == 0 " class="ml=2">
            <b-row>
                <b-col md="3"></b-col>
                <b-col md="6">
                    <b-img fluid :src="sideImg"  style="float: left; width: 100%;" />
                </b-col>
            </b-row>
            <div>

                <span class="ml-2 text-warning">Untuk saat ini belum ada data yang bisa kami tampilkan </span>
            </div>
        </div>
        </b-col>
        
        <b-col cols="6"> 
            <small><strong>Total Row {{ items.length }}</strong></small>
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
  </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,BImg,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,BImg,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props:{
            items : {},
            st : {}
        },
        data() {
            return {
                sideImg: require('@/assets/images/logo/ddk.png'),
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'tagihan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }, ],
              
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },emits:['get_histori_pembayaran'],
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            getHistori (val){
                this.$emit('get_histori_pembayaran', val)
            },
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },  
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>